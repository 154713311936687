import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import useForm, { FormProps, stringFromModelValue, validation } from '@calm-web/use-form';

import { setBannerMessage } from '@/store/actions';
import { AssignmentRuleOperator, HealthAssignmentRuleAttribute, HealthSponsorship } from '@/types/health';
import { iso8601Date, utcToIso8601Date } from '@/utils/helpers';

import { HealthSponsorshipPayload } from '../api/useHealthSponsorships';
import { useDefinedPartner } from '../api/usePartner';
import { usePermissions } from '../auth';
import {
	EditHealthAssignmentOperatorFormProps,
	EditHealthAssignmentRuleAttributeFormProps,
	useHealthAssignmentRuleForm,
} from './useHealthAssignmentRuleForm';

type FieldNames =
	| 'display_name'
	| 'description'
	| 'starts_at'
	| 'ends_at'
	| 'auto_expire_duration'
	// | 'eligibility_grace_period_duration'
	| 'is_demo';

export type EditHealthSponsorshipFormProps = FormProps<FieldNames>;

const useBaseHealthSponsorshipForm = (
	sponsorship?: HealthSponsorship,
): { formProps: EditHealthSponsorshipFormProps; hasChangedAny: boolean; hasTouchedAny: boolean } => {
	const partner = useDefinedPartner();
	const formProps: EditHealthSponsorshipFormProps = useForm('healthSponsorshipForm', {
		initialModel: {
			display_name: sponsorship?.display_name ?? '',
			description: sponsorship?.description ?? '',
			starts_at: sponsorship?.starts_at
				? utcToIso8601Date(new Date(sponsorship?.starts_at))
				: iso8601Date(new Date()),
			ends_at: sponsorship?.ends_at
				? utcToIso8601Date(new Date(sponsorship?.ends_at))
				: iso8601Date(new Date(partner.contract_expires_at)),
			auto_expire_duration: sponsorship?.auto_expire_duration ?? 'null',
			// eligibility_grace_period_duration: sponsorship?.eligibility_grace_period_duration ?? '',
			is_demo: sponsorship?.is_demo ? 'true' : 'false',
		},
		validation: {
			display_name: validation.validateOrFail([
				{
					rules: [validation.required],
					errorResult: 'Please enter a group name',
				},
			]),
			starts_at: validation.validateOrFail([
				{
					rules: [validation.required],
					errorResult: 'Please enter a start date',
				},
			]),
			ends_at: validation.validateOrFail([
				{
					rules: [validation.required],
					errorResult: 'Please enter an end date',
				},
			]),
		},
	});

	const hasChangedAny = !!Object.values(formProps.dirtyState).some(value => value?.hasChanged);
	const hasTouchedAny = !!Object.values(formProps.dirtyState).some(value => value?.hasTouched);

	return { formProps, hasChangedAny, hasTouchedAny };
};

export const useHealthSponsorshipForm = (
	isInternationalConfigFlagTrue: boolean,
	isSponsorCodePartner: boolean,
	sponsorship?: HealthSponsorship,
	isDefault = false,
	isDependentGroup = false,
): {
	baseFormProps: EditHealthSponsorshipFormProps;
	assignmentRuleOperatorFormProps: EditHealthAssignmentOperatorFormProps;
	assignmentRuleAttributeFormProps: EditHealthAssignmentRuleAttributeFormProps[];
	hasChangedAny: boolean;
	hasTouchedAny: boolean;
	addAssignmentRuleAttribute: () => void;
	removeAssignmentRuleAttribute: (index: number) => void;
} => {
	const baseForm = useBaseHealthSponsorshipForm(sponsorship);
	const assignmentRuleForms = useHealthAssignmentRuleForm(
		isInternationalConfigFlagTrue,
		isSponsorCodePartner,
		sponsorship?.assignment_rules?.[0],
		isDefault,
		isDependentGroup,
	);

	const hasChangedAny = baseForm.hasChangedAny || assignmentRuleForms.hasChangedAny;
	const hasTouchedAny = baseForm.hasTouchedAny || assignmentRuleForms.hasTouchedAny;

	return {
		baseFormProps: baseForm.formProps,
		assignmentRuleOperatorFormProps: assignmentRuleForms.operatorFormProps,
		assignmentRuleAttributeFormProps: assignmentRuleForms.assignmentRuleAttributeFormProps,
		hasChangedAny,
		hasTouchedAny,
		addAssignmentRuleAttribute: assignmentRuleForms.addAssignmentRuleAttribute,
		removeAssignmentRuleAttribute: assignmentRuleForms.removeAssignmentRuleAttribute,
	};
};

const getErrorMessage = (
	props:
		| EditHealthSponsorshipFormProps
		| EditHealthAssignmentOperatorFormProps
		| EditHealthAssignmentRuleAttributeFormProps,
): string | undefined => {
	const genericProps = props as FormProps<string>;
	return stringFromModelValue(
		(Object.keys(props.validation.fields) as FieldNames[])
			.map(fieldName => stringFromModelValue(genericProps.validation.fields[fieldName]?.errors))
			.filter(Boolean) as string[],
	);
};

export const useHealthSponsorshipSubmitData = (
	baseFormProps: EditHealthSponsorshipFormProps,
	operatorFormProps: EditHealthAssignmentOperatorFormProps,
	assignmentRuleAttributeFormProps: EditHealthAssignmentRuleAttributeFormProps[],
): {
	getHealthSponsorshipSubmitData: (isDependentGroup?: boolean) => Partial<HealthSponsorshipPayload>;
	showValidationErrors: () => boolean;
} => {
	const dispatch = useDispatch();
	const [hasValidPermissions, actions] = usePermissions();

	const getHealthSponsorshipSubmitData = useCallback(
		(isDependentGroup = false): Partial<HealthSponsorshipPayload> => {
			const baseSponsorshipData: Partial<HealthSponsorshipPayload> = {
				display_name: stringFromModelValue(baseFormProps.model.display_name),
				description: stringFromModelValue(baseFormProps.model.description),
				starts_at: `${stringFromModelValue(baseFormProps.model.starts_at)}T00:00:00Z`,
				ends_at: `${stringFromModelValue(baseFormProps.model.ends_at)}T00:00:00Z`,
				auto_expire_duration:
					baseFormProps.model.auto_expire_duration === 'null'
						? null
						: stringFromModelValue(baseFormProps.model.auto_expire_duration),
				// eligibility_grace_period_duration: stringFromModelValue(baseFormProps.model.eligibility_grace_period_duration),
				is_demo: baseFormProps.model.is_demo === 'true',
				is_dependent_group: isDependentGroup,
			};

			Object.keys(baseSponsorshipData).forEach((fieldName: string) => {
				if (!hasValidPermissions(`health_sponsorship_${fieldName}`, [actions.UPDATE])) {
					delete baseSponsorshipData[fieldName as keyof HealthSponsorshipPayload];
				}
			});

			const assignmentRuleData = assignmentRuleAttributeFormProps.map(props => ({
				type: props.model.type as string,
				value: props.model.value,
			}));

			const payload: Partial<HealthSponsorshipPayload> = {
				...baseSponsorshipData,
				assignment_rules: [
					{
						operator: operatorFormProps.model.operator as AssignmentRuleOperator,
						assignment_rule:
							assignmentRuleData.length === 0
								? null
								: { attributes: assignmentRuleData as HealthAssignmentRuleAttribute[] },
					},
				],
			};
			return payload;
		},
		[
			actions.UPDATE,
			assignmentRuleAttributeFormProps,
			baseFormProps.model,
			operatorFormProps.model,
			hasValidPermissions,
		],
	);

	const showValidationErrors = useCallback((): boolean => {
		const allFormProps = [baseFormProps, operatorFormProps, ...assignmentRuleAttributeFormProps];
		if (operatorFormProps.model?.operator === 'and') {
			// check if there are any duplicate rule types
			const allTypes = assignmentRuleAttributeFormProps.map(props => props.model.type);
			const uniqueTypes = new Set(allTypes);
			if (allTypes.length !== uniqueTypes.size && allTypes.length > 1 && allTypes[0] !== 'segment') {
				dispatch(
					setBannerMessage({
						message: "You cannot use the 'AND' operator with duplicate rule types",
						flash: true,
						isError: true,
					}),
				);
				return true;
			}
		}
		if (allFormProps.every(props => props.validation.isValid)) {
			return false;
		}
		const errorMessage =
			stringFromModelValue(allFormProps.map(getErrorMessage).filter(Boolean) as string[]) ??
			'Please check you have filled out all required fields';
		dispatch(
			setBannerMessage({
				message: `Error: ${errorMessage}`,
				flash: true,
				isError: true,
			}),
		);
		return true;
	}, [dispatch, baseFormProps, operatorFormProps, assignmentRuleAttributeFormProps]);

	return { getHealthSponsorshipSubmitData, showValidationErrors };
};
